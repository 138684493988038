<script lang="ts" setup>
import type { Cardholder } from '~/types/cardholder'

const { user: currentUser } = useUser()
const cardholderStore = useCardholder()
const { cardholder: currentCardholder } = useCardholder()

const selected = computed({
  get() {
    if (!currentCardholder.value) {
      const storedCardholder = localStorage.getItem(
        'DX:cardholder:selected-cardholder:v1'
      )
      if (storedCardholder) {
        cardholderStore.setCardholder(
          JSON.parse(storedCardholder) as Cardholder
        )
      } else {
        cardholderStore.setCardholder(currentUser.value!.card_holders![0])
      }
    }
    return {
      label: currentCardholder.value?.name ?? '',
      value: currentCardholder.value?.id ?? -1,
    }
  },
  set(newVal: { label: string; value: number }) {
    const newCardholder = currentUser.value?.card_holders?.find(
      (item) => item.id === newVal.value
    )
    if (newCardholder) {
      cardholderStore.setCardholder(newCardholder)
    }
  },
})

function selectCardholder(newCardholder: Cardholder) {
  selected.value = {
    label: newCardholder.name,
    value: newCardholder.id,
  }
}

const options = computed(() => {
  return (currentUser.value?.card_holders ?? []).map((cardHolder) => {
    return {
      label: cardHolder.name,
      value: cardHolder.id,
    }
  })
})

const localePath = useLocalePath()
function linkToProfile(id: number) {
  navigateTo(
    localePath({
      name: 'user-profile-profileId',
      params: { profileId: id.toString() },
    })
  )
}

const phoneOpen = ref(false)
</script>

<template>
  <div>
    <!--     DESKTOP  -->
    <div
      class="hidden w-full items-center justify-start gap-4 bg-black p-2 px-10 text-lg uppercase sm:w-max md:flex"
    >
      <span class="hidden text-white lg:flex">
        {{ $t('cardholder.active') }}
      </span>
      <UiSelect
        v-model="selected"
        class="hidden md:block"
        :options="options"
        button-class="max-w-[200px] md:max-w-full"
        options-class="pt-[57px] -mt-[57px] text-white"
        :add-new-link="
          localePath({
            name: 'user-profile-create',
          })
        "
      >
        <template #item-append="{ item }">
          <UiIconButton
            name="mdi:pencil"
            :translated-tooltip="$t('cardholder.edit')"
            size="20"
            @click="linkToProfile(item)"
          />
        </template>
      </UiSelect>
    </div>
    <!--     PHONE -->
    <div class="!w-full">
      <UiPopover v-model="phoneOpen" class="z-50 md:hidden" :offset-size="0">
        <template #activator>
          <UiButton class="w-screen">
            <div class="grid w-full grid-cols-6 overflow-x-hidden py-2 text-xl">
              <div class="mr-auto text-lg normal-case !text-white">
                {{ $t('cardholder.active') }}
              </div>
              <div
                class="col-span-4 mx-auto w-full overflow-x-hidden text-ellipsis px-3 !normal-case !text-primary"
              >
                {{ currentCardholder?.name ?? '' }}
              </div>
              <Icon
                size="25"
                class="ml-auto min-w-[15px] text-white transition-all"
                name="fa6-solid:chevron-down"
                :class="{ 'rotate-180': phoneOpen }"
              />
            </div>
          </UiButton>
        </template>
        <div
          class="flex max-h-[250px] w-screen flex-col gap-1 overflow-y-auto overflow-x-hidden border-t-2 border-primary bg-black px-5 py-3 text-white"
        >
          <div
            v-for="cardholder in currentUser?.card_holders ?? []"
            :key="cardholder.id"
            class="flex justify-between gap-x-10 py-2 text-xl hover:!text-primary"
            @click="selectCardholder(cardholder)"
          >
            <div class="overflow-x-hidden text-ellipsis">
              {{ cardholder.name }}
            </div>
            <UiIconButton
              name="mdi:pencil"
              classes="text-white"
              :translated-tooltip="$t('cardholder.edit')"
              size="25"
              @click="linkToProfile(cardholder.id)"
            />
          </div>
          <div class="mx-auto mt-2 text-lg !text-primary">
            <NuxtLink
              :to="
                localePath({
                  name: 'user-profile-create',
                })
              "
            >
              {{ $t('cardholder.create') }}
              <Icon name="gg:add" size="35" />
            </NuxtLink>
          </div>
        </div>
      </UiPopover>
    </div>
  </div>
</template>

<style scoped></style>
