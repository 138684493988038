<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core'
import UiFooter from '~/components/Ui/UiFooter.vue'
const target = ref<HTMLElement | null>(null)
const targetIsVisible = ref(false)
useIntersectionObserver(target, ([{ isIntersecting }]) => {
  targetIsVisible.value = isIntersecting
})
const { user } = useUser()

const userName = computed(() => {
  return user.value?.email ?? ''
})
</script>

<template>
  <div
    id="content"
    class="bg-defaultBg h-screen w-full overflow-auto bg-cover bg-center"
  >
    <UiHeader>
      <template #sidebar>
        <UiSidebar position="left" class="bg-white opacity-95">
          <template #activator="{ open }">
            <div class="xl:hidden">
              <UiIconButton
                classes="text-white"
                name="cil:hamburger-menu"
                size="30"
                @click="open"
              />
            </div>
          </template>
          <template #alt-activator="{ open }">
            <Transition
              enter-from-class="opacity-0"
              enter-to-class="opacity-100"
              enter-active-class="duration-500"
              leave-active-class="!duration-100 opacity-0"
            >
              <div
                v-if="!targetIsVisible && user && !user.admin"
                class="fixed right-2 top-2 z-50 rounded-full bg-black p-3 drop-shadow-xl transition-all md:hidden"
              >
                <UiIconButton
                  classes="text-white"
                  name="cil:hamburger-menu"
                  size="35"
                  @click="open"
                />
              </div>
            </Transition>
          </template>
          <template #default="{ close }">
            <div class="mb-5 h-[calc(100vh-5rem)] pt-1">
              <div class="mb-2 flex justify-between pb-3 text-xl">
                <div>
                  {{ userName }}
                </div>
                <UiIconButton
                  classes="text-primary"
                  name="fa6-solid:xmark"
                  size="30"
                  @click="close"
                />
              </div>
              <SideMenu @link-clicked="close" />
            </div>
          </template>
        </UiSidebar>
      </template>
    </UiHeader>
    <div class="min-h-footer w-full bg-black bg-opacity-30 pb-5 md:pb-20">
      <div class="container mx-auto pt-5 md:pt-20">
        <CardholderControl v-if="user && !user.admin" ref="target" />
        <div
          class="flex gap-20 bg-white bg-opacity-90 px-2 py-5 sm:px-5 lg:py-16"
        >
          <SideMenu class="md sticky top-3 max-xl:hidden" />
          <div class="container overflow-hidden overscroll-x-auto">
            <slot />
          </div>
        </div>
      </div>
    </div>
    <UiFooter />
  </div>
</template>

<style scoped></style>
