<script lang="ts" setup>
const props = defineProps<{
  text: string
  link: string
  active: boolean
}>()

const base = 'text-xl py-1 px-2 w-max '
const cls = computed(() => `${base}${props.active ? 'font-extrabold ' : ''}`)
</script>

<template>
  <NuxtLink :to="link" class="group" :class="cls">
    {{ text.toUpperCase() }}
    <div
      class="h-[2px] w-0 !bg-black transition-all duration-500 group-hover:block group-hover:w-full"
      :class="{ '!w-full duration-0': active }"
    />
  </NuxtLink>
</template>
