<script setup lang="ts">
import type { Placement } from '@floating-ui/vue'
import { useElementSize } from '@vueuse/core'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const props = withDefaults(
  defineProps<{
    placement?: Placement
    offsetSize?: number
  }>(),
  {
    placement: 'bottom',
    offsetSize: 0,
  }
)
const isOpen = defineModel<boolean>()
const { anchor, floating, floatingStyles } = useUiFloating({
  placement: props.placement,
  offsetSize: props.offsetSize,
  strategy: 'fixed',
})

const { height } = useElementSize(floating)
const localIsOpen = computed(() => {
  return height.value !== 0
})

watch(
  () => localIsOpen.value,
  () => {
    isOpen.value = localIsOpen.value
  }
)

function closePopover(closeFnc: () => void) {
  closeFnc()
}
</script>

<template>
  <Popover class="relative">
    <PopoverButton ref="anchor" as="button" class="flex outline-none">
      <slot name="activator">
        <UiButton size="md" type="primary">Open</UiButton>
      </slot>
    </PopoverButton>
    <div ref="floating" class="max-w-[100vw]" :style="floatingStyles">
      <Transition
        appear
        enter-active-class="motion-safe:transition duration-300"
        enter-from-class="opacity-0 translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="motion-safe:transition duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-1"
      >
        <PopoverPanel v-slot="{ close }" class="!z-40 bg-background">
          <div @click="closePopover(close)">
            <slot></slot>
          </div>
        </PopoverPanel>
      </Transition>
    </div>
  </Popover>
</template>
